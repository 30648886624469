import React, {useState} from "react";
import {Callout, Card, H1, OverlayToaster} from "@blueprintjs/core";
import Dropzone from "react-dropzone";
import {Grid} from "./basic/Grid";
import {useGlobalContext} from "./GlobalContext";

let toaster = OverlayToaster.create({position: "top-left"});

export function Upload(props) {
    const [error, setError] = useState();
    const {reload} = useGlobalContext().depot;

    return (
        <Grid gap="20px">
            {error &&
                <div style={{border: "2px solid red"}}>{error}</div>}
            <Card>
                <H1>Swissquote</H1>
                <Dropzone onDrop={files => fileUpload(files, "swissquote", setError, reload)}>
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <div {...getRootProps()}>
                            <Callout
                                icon="cloud-upload" title="File Upload" intent={isDragActive ? "primary" : "none"}
                                style={{cursor: "pointer"}}
                            >
                                <input {...getInputProps()} />
                                Per Drag and Drop oder klicken neue Files hochladen.
                            </Callout>
                        </div>
                    )}
                </Dropzone>
            </Card>
            <Card>
                <H1>UBS</H1>
                <Dropzone onDrop={files => fileUpload(files, "ubs", setError, reload)}>
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <div {...getRootProps()}>
                            <Callout
                                icon="cloud-upload" title="File Upload" intent={isDragActive ? "primary" : "none"}
                                style={{cursor: "pointer"}}
                            >
                                <input {...getInputProps()} />
                                Per Drag and Drop oder klicken neue Files hochladen.
                            </Callout>
                        </div>
                    )}
                </Dropzone>
            </Card>
            <Card>
                <H1>Interactive Brokers</H1>
                <Dropzone onDrop={files => fileUpload(files, "interactivebrokers", setError, reload)}>
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <div {...getRootProps()}>
                            <Callout
                                icon="cloud-upload" title="File Upload" intent={isDragActive ? "primary" : "none"}
                                style={{cursor: "pointer"}}
                            >
                                <input {...getInputProps()} />
                                Per Drag and Drop oder klicken neue Files hochladen.
                            </Callout>
                        </div>
                    )}
                </Dropzone>
            </Card>
            {props.budgetEnabled &&
                <Card>
                    <H1>UBS Bankkonto</H1>
                    <Dropzone onDrop={files => fileUpload(files, "ubs-account", setError, reload)}>
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <div {...getRootProps()}>
                                <Callout
                                    icon="cloud-upload" title="File Upload" intent={isDragActive ? "primary" : "none"}
                                    style={{cursor: "pointer"}}
                                >
                                    <input {...getInputProps()} />
                                    Per Drag and Drop oder klicken neue Files hochladen.
                                </Callout>
                            </div>
                        )}
                    </Dropzone>
                </Card>}
        </Grid>
    )
}

function fileUpload(files, type, setError, reload) {
    const formData = new FormData();
    formData.append("file", files[0]);
    fetch(`/api/fileimport/${type}`, {
        method: "POST",
        body: formData
    }).then(res => {
        if (res.ok) {
            console.log("File uploaded", files[0]);
            toaster.show({
                icon: "cloud-upload",
                message: `File uploaded: ${files[0].name}`,
                intent: "success",
            });
            reload();
        } else {
            res.text().then(content => {
                toaster.show({
                    icon: "cloud-upload",
                    message: `Failed to upload: ${content}`,
                    intent: "danger",
                });
                setError(content);
            });
        }
    });
}