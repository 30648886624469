import React from "react";
import {
    Boundary,
    Button,
    Card,
    Classes,
    FocusStyleManager,
    Menu,
    NavbarDivider,
    OverflowList,
    OverlaysProvider,
    Popover,
} from "@blueprintjs/core";
import {BrowserRouter, NavLink, Redirect, Route, Switch} from "react-router-dom";
import {Watchlist} from "./watchlist/Watchlist";
import {Upload} from "./Upload";
import {Login} from "./login/Login";
import {Depot} from "./depot/Depot";
import {BigNumber} from "bignumber.js";
import {GroupComparison} from "./stockdetail/GroupComparison";
import {StockDetailPage} from "./stockdetail/StockDetailPage";
import logo from "../logo.png"
import {Grid} from "./basic/Grid";
import {ErrorBoundary} from "@sentry/react";
import {ErrorFallback} from "./basic/ErrorFallback";
import {SettingsPage} from "./SettingsPage";
import {GlobalContextProvider, useGlobalContext} from "./GlobalContext";
import {BudgetPage} from "./budget/BudgetPage";
import {BudgetCategorization} from "./budget/BudgetCategorization";
import {BudgetYear} from "./budget/BudgetYear";
import {LoginContextProvider} from "./LoginContext";
import {TaxStatementPage} from "./taxstatement/TaxStatementPage";

FocusStyleManager.onlyShowFocusOnTabs();
BigNumber.set({
    FORMAT: {
        groupSize: 3,
        groupSeparator: "'",
        decimalSeparator: "."
    }
})

export function App() {
    return (
        <OverlaysProvider>
            <div
                style={{
                    padding: window.innerWidth < 900 ? "0px" : "10px"
                }}
            >
                <BrowserRouter basename="/">
                    <LoginContextProvider>
                        <Login>
                            <GlobalContextProvider>
                                <AppContent/>
                            </GlobalContextProvider>
                        </Login>
                    </LoginContextProvider>

                </BrowserRouter>
            </div>
        </OverlaysProvider>
    );
}

function AppContent() {
    let {data, loading, reload} = useGlobalContext().userSettings;
    let budgetEnabled = !loading && data["budget_enabled"];

    return <>
        <nav>
            <Card style={{padding: "10px 15px", marginBottom: "10px"}}>
                <Grid columns="auto auto 1fr auto auto" align="center">
                    <div>
                        <Grid columns="auto auto" align="center" gap="10px">
                            <img src={logo} height={24} width={24} alt="Brisi"/>
                            <span style={{fontSize: "16px"}}>Brisi</span>
                        </Grid>
                    </div>
                    <NavbarDivider/>
                    <OverflowList
                        collapseFrom={Boundary.END}
                        observeParents={true}
                        items={[
                            {path: "/watchlist", text: "Watchlist"},
                            {path: "/depot", text: "Depot"},
                            {path: "/comparison", text: "Gruppen Vergleich"},
                            ...(budgetEnabled ? [{path: "/budget", text: "Budget"}] : []),
                            ...(budgetEnabled ? [{path: "/taxstatement/2023", text: "Steuerauszug"}] : []),
                            {path: "/upload", text: "File Upload"},
                            {path: "/settings", text: "Einstellungen"},
                        ]}
                        visibleItemRenderer={item =>
                            <CustomLink
                                key={item.path}
                                to={item.path}
                            >
                                {item.text}
                            </CustomLink>}
                        overflowRenderer={overflowItems => <Popover
                            content={
                                <Menu>
                                    {overflowItems.map(item => <MenuLink
                                        key={item.path}
                                        to={item.path}
                                    >
                                        {item.text}
                                    </MenuLink>)}
                                </Menu>
                            }
                        >
                            <Button minimal icon="more"/>
                        </Popover>}
                    />
                    <NavbarDivider/>
                    <Button
                        style={{alignSelf: "left"}}
                        icon="log-out"
                        minimal
                        onClick={() => fetch("/api/logout", {method: "POST", redirect: "follow"})
                            .then(res => {
                                if (res.status === 200 && res.redirected) {
                                    window.location.href = "/";
                                }
                            })}
                    />
                </Grid>
            </Card>
        </nav>
        <main>
            <ErrorBoundary fallback={ErrorFallback}>
                <Switch>
                    <Route path="/" exact><Redirect to="/watchlist"/></Route>
                    <Route path="/watchlist"><Watchlist/></Route>
                    <Route path="/depot"><Depot/></Route>
                    <Route path="/comparison"><GroupComparison/></Route>
                    <Route path="/upload"><Upload budgetEnabled={budgetEnabled}/></Route>
                    <Route path="/stocks/:symbol/:market?"><StockDetailPage/></Route>
                    <Route path="/settings"><SettingsPage/></Route>
                    <Route path="/budget" exact><BudgetPage/></Route>
                    <Route path="/budget/categorize"><BudgetCategorization/></Route>
                    <Route path="/budget/year/:year"><BudgetYear/></Route>
                    <Route path="/taxstatement/:year"><TaxStatementPage/></Route>
                </Switch>
            </ErrorBoundary>
        </main>
    </>;
}

function CustomLink({to, children}) {
    return (
        <NavLink
            to={to}
            exact
            className={[Classes.BUTTON, Classes.MINIMAL].join(" ")}
            activeClassName={[Classes.ACTIVE].join(" ")}
            style={{margin: "3px"}}
        >
            {children}
        </NavLink>
    )
}

function MenuLink({to, children}) {
    return (
        <li>
            <NavLink
                to={to}
                exact
                className={[Classes.MENU_ITEM].join(" ")}
                activeClassName={[Classes.ACTIVE].join(" ")}
            >
                <span style={{whiteSpace: "nowrap"}}>{children}</span>
            </NavLink>
        </li>
    )
}